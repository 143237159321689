body {
    font-family: 'IBM Plex Serif', serif;
}

.fig{
    text-align: center;
    margin-top: 5px;
}

.sources {
    text-align: left;
}

.imageTitleTV{
    position: absolute;
    left: 10px;
    bottom: 10px;
    margin-right: 10px!important;
    background-color: #61dafb;
}

.imageBlurbTV {
    text-align: left;
    font-size: 20px;
    color: black;
    padding: 5px !important;
    margin-bottom: 5px!important;
}

.allRow{
    margin-bottom: 200px;
}

.shareTextTV{
    text-align: left;
    margin-left: 0;
}

.subTitle {
    font-size: 16pt;
    margin-top: 5px;
    color: dimgrey;
}
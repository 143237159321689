.logo{
    height: 150px !important;
    width: 300px !important;
}

.darkGrey {
    color: #1b1e21;
    font-weight: light;
}

.footerAbout
{
    height: 60px;
    background-color: #61dafb;
    width: 100%;
}

.siteBlue{
   color: #61dafb;
}

.aboutBlurb {
    text-align: justify;
    font-size: 16px;
    color: #1b1e21;
}

.aboutDiv {
    text-align: left;
    max-width: 100%;
    margin: 10px;
}

@media only screen and (max-width: 768px) {
    .aboutBlurb {
        text-align: left;
        font-size: 18px;
    }
    
    .logo2 {
        display: none;
    }
}

.aboutLink{
    color: #61dafb;
}
body {
    font-family: 'IBM Plex Serif', serif;
}

.search{
    max-width: 300px;
}

.imageBox-recipes{
    width: 100%;
    height: 100%;
}

.recipeCard {
    text-align: left;
    margin-bottom: 10px;
    border: 0!important;
}

.shareImage{
    padding: 0 !important;
}

.imageTitle{
    position: absolute;
    left: 10px;
    bottom: 10px;
    margin-right: 10px!important;
    background-color: #61dafb;
}

.shareTextRecipe{
    text-align: left;
    margin-left: 0;
}

.imageBlurb {
    text-align: left;
    font-size: 20px;
    color: black;
    padding: 5px !important;
    margin-bottom: 5px!important;
}

.allRow{
    margin-bottom: 200px;
}
body {
    font-family: 'IBM Plex Serif', serif;
}

.movieImage {
    width: 100%;
}

.movieDate {
    font-size: 16px;
    color: #4e555b;
}

.movieDiv {
    text-align: left;
    max-width: 100%;
    margin: 5px;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;
}

.diversityDiv {
    text-align: left;
    max-width: 100%;
    margin: 5px;
    margin-top: 10px;
}

.movieBlurb {
    text-align: justify;
    font-size: 16px;
    color: #1b1e21;
}

.diversityCard{
    text-align: left;
    padding: 10px;
    margin-bottom: 10px;
}

.moviesCard{
    text-align: left;
    margin-bottom: 10px;
    border: 0!important;
}

.redBox{
    /*background-color: #ed969e !important;*/
    text-align: left;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ed969e !important;
    padding-bottom: 0!important;
}

.greenBox{
    /*background-color: #1abc9c !important;*/
    text-align: left;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #1abc9c !important;
    padding-bottom: 0!important;
}

.diversityCol{
    padding-top:40px;
}

.leftText {
    text-align: left;
}

.endBit{
    margin-bottom: 60px;
}

.italics{
    font-style: italic;
}

h3{
    font-family: "Roboto"!important;
}

@media only screen and (max-width: 768px) {
    .diversityCol{
        padding-top: 0;
        margin-bottom: 80px;
    }

    .diversityDiv {
        text-align: left;
        max-width: 100%;
        margin: 5px;
        margin-top: 10px;
    }

    .movieRow {
        margin-left: 0!important;
        margin-right: 0!important;
    }

    .movieDiv{
        margin-left: 5px!important;
        margin-right: 5px!important;
    }

    .endBit{
        margin-bottom: 10px;
    }
}

body {
    overflow-x: hidden;
}

.row {

}

.imageBox{
    width: 100%;
    height: 100%;
}

.shareText{
    text-align: left;
    margin-left: 10px;
}

.floatLeft{
    float: left;
    margin-left: 5px;
}

.imageTitle{
    position: absolute;
    left: 10px;
    bottom: 10px;
    margin-right: 10px!important;
    background-color: #61dafb;
}

.imageBlurb {
    text-align: left;
    font-size: 20px;
    color: black;
    padding: 5px !important;
    margin-bottom: 5px!important;
}

.allRow{
    margin-bottom: 200px;
}

.facebookButton{
    float: left;
    margin-top: -25px;
    margin-left: 10px;
}
body {
    font-family: 'IBM Plex Serif', serif;
}

html
{
    height:100%;
}

body
{
    min-height:100%;
    padding:0; /*not needed, but otherwise header and footer tags have padding and margin*/
    margin:0; /*see above comment*/
}

body
{
    position:relative;
    padding-bottom:60px; /* Same height as the footer. */
}

.footer
{
    position:absolute;
    bottom:0px;
    height: 60px;
    background-color: #61dafb;
    width: 100%;
}

.footerLinks {
    color: #1a1446;
    font-size: 20px;
    float: left;
    margin-left: 20px;
    margin-top: 10px;
}

.bg-color {
   /* background-color: #3b064d;*/
}

/*.Home .img-responsive {
    margin: 0 auto;
}*/

.box-logo {
    width: 220px;
    height: 80px;
    margin: 0 !important;
}

.blurb{
    color: #1abc9c;
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
}

.storeicon {
    width: 300px!important;
    height: auto!important;
    text-align: center;
    margin: 0 auto!important;
}

.boxapopa {
    color: #ba2ecc;
}

.fungame {
    color: #0496ff;
}

.kids {
    color: #ffbc42;
}

.blurbCard {

    background-color: white !important;
    border: 0 !important;
    padding: 15px !important;
    width: 300px !important;
    margin: 0 auto!important;
    padding-top: 30px !important;

}

.boxImage {
    width: 120px;
    height: 120px;
    margin: 0 auto;
}

.blurbRow {
    margin: 0 auto!important;
    padding: 10px;
    text-align: center;
}



.wotLink {
    font-size: 24px;
    color: white;
    text-decoration: underline;
}

.steakLink {
    font-size: 24px;
    color: white;
    text-decoration: underline;
}

.wotDiv {
    text-align: left;
    max-width: 330px;
    margin: 10px;
    max-height: 100px!important;
}

.tv{
    border-radius: 20px!important;
    background-color: transparent!important;
}

.tvCol{
    width:100% !important;
    /*max-width: 325px!important;*/
    min-width: 325px!important;
    border-radius: 20px!important;
    padding: 5px!important;
}

.jungleDiv {
    text-align: right;
    max-width: 330px;
    margin: 10px;
    float: right;
}

.rightLink {
    font-size: 24px;
    color: white;
    text-decoration: underline;
}

.cardLink{
    position: absolute;
    left: 10px;
    top: 10px;
    margin-right: 10px!important;
}

.cardLinkText{
    font-size: 24px;
    color: white;
    text-decoration: underline;
    text-align: left;
    padding: 5px !important;
    margin-bottom: 5px!important;
}

.cardLinkTextDark{
    font-size: 24px;
    color: #1a1446;
    text-decoration: underline;
    text-align: left;
    padding: 5px !important;
    margin-bottom: 5px!important;
}

.wotImage1 {
    object-fit: contain;
    width:100%;
    height:400px;
}

.sectionTitle{
    text-align: left;
}

.wotBlurb {
    font-size: 14px;
    color: white;
}

.cardRow{
    margin: 0 auto!important;
    margin-bottom: 10px !important;
}

.title{
    margin: 0 auto !important;
    color: #1a1446
}

.towtruck {
    margin: 0 auto !important;
}

.screenshots {

}

.endRecipes{
    margin-bottom: 40px!important;
}

.contentCard {
    text-align: left;
    border: 0!important;
}

.bgImageDiv {
    border-radius: 10px;
    padding: 0!important;
    margin: 0!important;
}

.movieSection{
    padding-top: 0;
}

.heroImageDiv {
    border-radius: 10px;
    padding: 0!important;
    margin: 0!important;
    height: 500px;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    padding-left: 0 !important;
    padding-right: 0 !important;
}


.screens{
    background-color: #3b064d;
    padding: 20px;
    max-height: 550px!important;
}

.grid1{
    background-color: #61dafb;
}

.links {
    color: #0496ff;
}

.social {
    max-width: 50px;
    max-height: 50px;
    padding:5px;
}

.divmor {
    /*background-color: #61dafb;*/
    height: 1200px;
}

.noadsBlurb {
    font-size: 14px;
    text-align: left;
}

.noadsBlurbDiv {
    background-color: white;
    padding: 15px;
    text-align: left;
    border-radius: 20px;
    margin-left: 5px;
    margin-right: 15px;
}

.url{
    font-size: large;
}


.aboutLink{
    text-align: left;
}


.titleLink{
    text-align: left;
}

.arrowIcon{
    margin-left: 10px;
}

.copyright{
    margin-top: 30px;
    margin-left: 10px;
}

.blurbCol{
    background-size:cover;
    border-radius: 20px !important;
    text-align: left!important;
}

.steakCol {
    min-height: 250px;
    background-size:cover;
    border-radius: 20px !important;
}

.steakRow1 {
    min-height: 150px;
    background-size:cover;
    border-radius: 20px !important;
}

.steakRow2 {
    background-color: #61dafb;
    background-image: url('../../assets/images/steak.jpg');
    min-height: 250px;
    background-size:cover;
    border-radius: 20px !important;
}

.wotCol {
    background-color: #61dafb;
    background-image: url('../../assets/images/tv/wot_001.JPG');
    min-height: 400px;
    background-size:cover;
    border-radius: 20px !important;
}

.moonCol {
    background-color: #61dafb;
    background-image: url('../../assets/images/tv/moonKnight2.jpg');
    min-height: 400px;
    background-size:cover;
    border-radius: 20px !important;
    max-height: 200px !important;
}

.duneCol {
    background-color: #61dafb;
    background-image: url('../../assets/images/dune001.jpg');
    min-height: 400px;
    background-size:cover;
    border-radius: 20px !important;
}

.jungleCol {
    background-color: #61dafb;
    background-image: url('../../assets/images/junglecruise.jpeg');
    min-height: 400px;
    background-size:cover;
    border-radius: 20px !important;
}
.steakDiv {
    text-align: left;
    max-width: 100%;
    margin: 10px;
}

.appDiv {
    text-align: left;
    max-width: 100%;
    margin: 10px;
}

.descDiv {
    text-align: left;
    max-width: 100%;
    margin: 10px;
    margin-top: 0!important;
}

.appBg {
    background-color: #61dafb;
    background-image: url('../../assets/images/muffin.jpg');
    min-height: 250px;
    background-size:cover;
    border-radius: 20px !important;
    text-align: left;
}

.muffinBg {
    background-color: #61dafb;
    background-image: url('../../assets/images/muffin.jpg');
    min-height: 250px;
    background-size:cover;
    border-radius: 20px !important;
    text-align: left;
}

.nachosBg {
    background-color: #61dafb;
    background-image: url('../../assets/images/nachos.jpg');
    min-height: 250px;
    background-size:cover;
    border-radius: 20px !important;
    text-align: left;
}

.openRangeBg {
    background-color: #61dafb;
    background-image: url('../../assets/images/tv/imogen_poots1.jpeg');
    min-height: 250px;
    background-size:cover;
    border-radius: 20px !important;
    text-align: left;
}


.yellowTV {
    width: 100%;
    height: 100%;
}

.blurbTitle{
    font-size: 18px !important;
    padding: 10px;
    text-align: left;
}

.writeTitle{
    font-size: 16px !important;
    padding: 10px;
    text-align: left;
}

.titleSpan {
    font-size: 30px;
}

.boldSpan {
    font-weight: bold;
}

.tileText{
    text-align: left;
    margin-top: 10px;
    font-size: 18px;
}

.latestTV{
    margin-top: 20px;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */

    .tileText{
        margin-bottom: 0!important;
    }

    .heroImageDiv {
        border-radius: 10px;
        padding: 0!important;
        margin: 0!important;
        height: 300px;
    }

    .movieSection{
        padding-top: 10px;
    }

    .footer
    {
        position:absolute;
        bottom: -500px;
        height: 60px;
        background-color: #61dafb;
        width: 100%;
    }


    .tvCol{
        width:100% !important;
        min-width: 325px!important;
        max-width: 100% !important;
        border-radius: 20px!important;
        margin: 5px!important;
        /*margin-left: 20px!important;
        margin-right: 20px!important;*/
    }

    .sectionTitle{
        margin-left: 10px;
    }

    .seperate{
        height:10px;
    }

    .noadsBlurbDiv {
        padding: 15px;
        text-align: left;
        border-radius: 20px;
        margin: 0 auto;
    }

    .wotCol {
        background-color: #61dafb;
        background-image: url('../../assets/images/tv/wot_001.JPG');
        min-height: 350px;
        background-size:cover;
        border-radius: 20px !important;
        max-height: 350px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 10px;
    }

    .moonCol {
        background-color: #61dafb;
        background-image: url('../../assets/images/tv/moonKnight2.jpg');
        min-height: 350px;
        background-size:cover;
        border-radius: 20px !important;
        max-height: 350px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 10px;
    }

    .duneCol {
        background-color: #61dafb;
        background-image: url('../../assets/images/dune001.jpg');
        min-height: 350px;
        background-size:cover;
        border-radius: 20px !important;
        max-height: 350px;
        margin-bottom: 10px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .jungleCol {
        background-color: #61dafb;
        background-image: url('../../assets/images/junglecruise.jpeg');
        min-height: 350px;
        background-size:cover;
        border-radius: 20px !important;
        max-height: 350px;
        margin-bottom: 10px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .steakCol {
        margin-bottom: 10px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .blurbCol {
        margin-bottom: 10px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .blurbTitle{
        font-family: 'IBM Plex Serif', serif;
        font-size: 18px !important;
        padding: 10px;
        text-align: left;
        /*margin-left: 10px;
        margin-right: 10px;*/
    }

    .descDiv {
        text-align: left;
        max-width: 100%;
        margin-left: 0px;
    }
}

.tv{
    width: 100%;
    min-height: 250px;
}

@media (min-width: 768px) {
    .wotCol {
        margin-right: 0px;
    }

    .moonCol {
        margin-right: 0px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .steakCol {
        margin-bottom: 10px;
    }
    .duneCol {
        margin-bottom: 10px;
    }
    .wotCol {
        margin-bottom: 10px;
    }
    .moonCol {
        margin-bottom: 10px;
    }
    .blurbCol {
        margin-bottom: 10px;
    }
}

.App {
  text-align: center;
}



.bg-light {
  background-color: #61dafb !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.navBrand {
  position: absolute;
  top: 10px;
  left: 15px;
  letter-spacing: 2px;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-app {
    padding: 0!important;
    max-height: 30px !important;
  padding-top: 20px!important;
}

.navbar {
  margin-bottom: 0px;
}

.navbar-brand {
  padding : 0!important;
  font-size: 20px !important;
  margin-left: 10px !important;
}



.navMenu {
  position: absolute;
  margin-top: 0px;
  right: 20px;
  font-size: small;
}

@media only screen and (max-width: 500px) {
  .navMenu {
    position: inherit;
    float: left;
    text-align: left;
    margin-top: 30px;
    padding-top: 10px;
    margin-left: 0;
    font-size: small;
  }

  .navbar-toggler {
    position: absolute;
    right: 10px;
    top: 15px;
  }


}

@media (min-width: 768px) {
  .navMenu {
    font-size: medium;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

@media (min-width: 768px){
  .navbar-expand-lg .navbar-collapse {
    display: flex!important;
    flex-basis: auto;
  }
}


.navbar-light .navbar-toggler {
  color: rgba(0,0,0,.5);
  border: 0px;
}

.navMenu {
  letter-spacing: 2px;
}

.siteLogo {
  height: 80px;
}
